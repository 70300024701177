import { Controller } from "stimulus"
import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = [ "macMidi", "ipadMidi" ]

  initialize() {
  }

  connect() {
  }
  
  platform(event) {
    const plat = event.srcElement.value
    $(this.macMidiTarget).toggle(plat != 'iPad')
    $(this.ipadMidiTarget).toggle(plat == 'iPad')
  }

}
